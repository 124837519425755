
import { getCookie, setCookie, deleteCookie } from "./cookies";

const tokenCookieName = "gydaToken";
const gydaRememberCookieName = "gydaRemember";


export class RollingSessions {

  constructor(manifestInstance) {
    this.manifestInstance = manifestInstance;
  }

  getAuthToken() {
    const tokenCookie = getCookie(tokenCookieName);
    try {
      const token = JSON.parse(tokenCookie).token;
      return token;
    } catch (e) {
      console.log('Failed to get token from cookie', e);
      return null;
    }
  }

  resetCookie(cname, cvalue, domain) {
    const rememberCookie = getCookie(gydaRememberCookieName);
    let expirationInDays = 1;

    let expires = new Date();
    expires.setDate(expires.getDate() + expirationInDays);

    if (rememberCookie === "1") {
      expirationInDays = 7;
    }

    setCookie(cname, cvalue, expires,domain);
    setCookie(gydaRememberCookieName, rememberCookie, expires, domain);
  }


  async initRollingSession(interval) {
    const retryInterval = interval || 300000; // default to 5min but the end user can change that if they want
    let resetToken;

    setInterval(async () => {
      try {
        const resetTokenResponse = await fetch(
          this.manifestInstance.resetTokenUrl,
          {
            headers: {
              Authorization: this.getAuthToken(),
            },
          }
        );
        resetToken = await resetTokenResponse.json();

        if (resetToken.code === 401) {
          // "Delete" the cookies
          deleteCookie(tokenCookieName);
          deleteCookie(gydaRememberCookieName);

          // LOGOUT
          window.location.href = `${
            this.manifestInstance.logoutUrl
          }?redirectTo=${encodeURIComponent(window.location.href)}`;
        }

        if (resetToken.token) {
          this.resetCookie(
            tokenCookieName,
            encodeURIComponent(JSON.stringify(resetToken)),
            this.manifestInstance.cookieDomain
          );
        }
      } catch (e) {
        console.log("Failed to fetch authorization for current token:", e);
      }
    }, retryInterval);
  }

}
